import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    {
      // eslint-disable-next-line
      <SEO title="404: Страница не найдена" />
    }
    <h1>404: Страница не найдена</h1>
    <p>К сожалению, запрошенная страница отсутствует.</p>
  </Layout>
)

export default NotFoundPage
